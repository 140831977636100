<template>
  <footer>

    <section id="donors">
      <strong>{{currentLangDict.NDI_title}}</strong>
      <img src="img/ndi_logo.png"/>
    </section>

    <section id="read_more">
      <h3>{{currentLangDict.readAlso.text}}</h3>
      <div>
        <a v-for="item in currentLangDict.readAlso.items"
          v-bind:key="item.link"
          :href="item.link"
          :style="`background:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('${item.img}');background-size:cover;`"
          target="_blank">
          <h2>{{item.alt}}</h2>
        </a>
      </div>
    </section>

    <section id="donate">
      <h5>{{currentLangDict.textyIS}} </h5>
      <a id="support-us" class="button" target="_blank" href="https://www.liqpay.ua/api/3/checkout?data=eyJ2ZXJzaW9uIjozLCJhY3Rpb24iOiJwYXlkb25hdGUiLCJwdWJsaWNfa2V5IjoiaTc2NjUwNTYyODI3IiwiYW1vdW50IjoiMTUwIiwiY3VycmVuY3kiOiJVQUgiLCJkZXNjcmlwdGlvbiI6ItCf0L7QttC10YDRgtCy0LAiLCJ0eXBlIjoiZG9uYXRlIiwibGFuZ3VhZ2UiOiJ1ayJ9&signature=tTFrEUyI4WV5l3N7G%2BYg5keUaXU%3D">{{currentLangDict.donate}}</a>

    </section>

    <section id="created_texty">
      <div>
        <a href="https://www.facebook.com/TEXTY.org.ua/" class="share-btn">
          <img src="img/facebook-app-symbol.svg"/>
        </a>
        <a href="https://twitter.com/textyorgua" class="share-btn">
          <img src="img/twitter-app-symbol.svg"/>
        </a>
        <h3>{{currentLangDict.createdBy}}</h3>
      </div>
      <div>
        <a href="http://texty.org.ua/" target="_blank"><img src="img/logo-texty-two-color.png" alt="texty.org.ua"></a>
      </div>
    </section>

    <section id="copyright">
      <!-- <p>© 2022, Тексти.org.ua</p> -->

      <!-- <p>Матеріали ТЕКСТИ.org.ua можна використовувати згідно ліцензії <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">Creative Commons із зазначенням авторства, CC BY</a> (переклад ліцензії <a href="https://creativecommons.org/licenses/by/4.0/legalcode.uk"  target="_blank">українською</a>). Велике прохання ставити гіперпосилання в першому чи другому абзаці вашого матеріалу.</p> -->
    </section>
  </footer>
</template>

<script>

// import { mapMutations } from 'vuex'


export default {
  name: "textyfooter",
//   methods: {
//     ...mapMutations([
//       'changeLang',
//     ]),
//     changeLang(){
//       this.$store.commit('changeLang')
//     }
//   },
  data() {
    return {
        tranlationEng: {
          "NDI_title": "The project was funded by the National Democratic Institute",
          // "ndiLogo": "img/ndi_logo_eng.png",
          "readAlso": {
            "text": "Read also:",
            "items": [
              {
                "link": "https://texty.org.ua/d/2023/germs_v2/",
                "img": "https://texty.org.ua/media/images/cover_f2iO7z7.original.png",
                "alt": "Бактерії “русского міра”. Версія 2.0",
              },
              {
                "link": "https://texty.org.ua/projects/108161/telegram-occupation-how-russia-wanted-breed-media-monster-ended-paper-tiger/",
                "img": "https://texty.org.ua/media/images/slidesnetwork26.original.jpg",
                "alt": "Telegram Occupation. How Russia Wanted to Breed a Media Monster, but Ended up with a Paper Tiger.",
              },
              {
                "link": "https://texty.org.ua/projects/109040/a-year-of-war/",
                "img": "https://github.com/texty/war_one_year/raw/main/eng_cover.jpg",
                "alt": "A year of war",
              },
            ],
          },
          "textyIS": "TEXTY.ORG.UA — independent media without annoying advertising and paid news. We need your support to continue our work.",
          "donate": "Donate",
          "createdBy": "Created by Texty",
          },
        translationUK: {
          "NDI_title": "Проект профінансовано National Democratic Institute",
          // "ndiLogo": "img/ndi_logo.png",
          "readAlso": {
            "text": "Читайте також:",
            "items": [
              {
                "link": "https://texty.org.ua/d/2023/germs_v2/",
                "img": "https://texty.org.ua/media/images/cover_f2iO7z7.original.png",
                "alt": "Бактерії “русского міра”. Версія 2.0",
              },
              {
                "link": "https://texty.org.ua/projects/108016/telehram-okupaciya-yak-rosiya-vybudovuvala-mediamerezhu-vyjshlo-potomkinske-selo/",
                "img": "https://texty.org.ua/media/images/slidesnetwork26.original.jpg",
                "alt": "Телеграм-окупація. Як Росія вибудовувала медіамережу, а вийшло потьомкінське село",
              },
              {
                "link": "https://texty.org.ua/projects/109018/rik-vijny/",
                "img": "https://github.com/texty/war_one_year/raw/main/cover.jpg",
                "alt": "Рік війни у графіках",
              },
            ],
          },
          "textyIS": "TEXTY.ORG.UA — незалежне видання без навʼязливої реклами й замовних матеріалів. Щоб працювати далі, нам потрібна ваша підтримка.",
          "donate": "Пожертвувати",
          "createdBy": "Створено Текстами",
          "license":  `<p>Матеріали ТЕКСТИ.org.ua можна використовувати згідно ліцензії <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">Creative Commons із зазначенням авторства, CC BY</a> (переклад ліцензії <a href="https://creativecommons.org/licenses/by/4.0/legalcode.uk"  target="_blank">українською</a>). Велике прохання ставити гіперпосилання в першому чи другому абзаці вашого матеріалу.</p>`,
          },          
      }
    },
  computed: {
        currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
      },
      
    }
}
</script>

<style lang="scss">
@import './assets/scss/footer.scss';
</style>

