<template>
<div>
<slot></slot>
<section id="vis">
  <div class="header-side">
    <p>{{currentLangDict.period}}</p>
  </div>
  <header-time :days="days"/>
  <div class="header-side"></div>

  <article id="subtopic-view">

    <svg v-if="!isMobile" id="subtitle-lines" viewBox="0 0 350 30" preserveAspectRatio="none">
      <path d="M0 6 L135 6 L135 16 L250 16 L250 26 L 280 26"
      vector-effect="non-scaling-stroke"/>
    </svg>

    <nav id="metatopic-menu">
      <h4 class="subtopheader">
        <div>{{currentLangDict.topic}}</div>
        <router-link v-if="isMobile" :to="{path: '/', query: $route.query}" 
          class="back">{{currentLangDict.home}}</router-link>
      </h4>
      <h2>{{ meta2name[sub_slug] }}</h2>
      <p class="legend">{{currentLangDict.binDescr}}</p>
      <figure id="metatopic-chart">
        <chart :daily="metatopic"
          :slug="`mt_${sub_slug}`"
          :days="days"
          :axis="true"
          :annotate="true"
          :tooltip="!isTouch"
          :maxY="maxY"
        />
      </figure>


      <!-- <p id="for-site-menu" v-if="!isMobile">Типи сайтів:</p> -->
      <!-- <sitetype :no_toggle="!isMobile"/> -->
      <router-link v-if="!isMobile" :to="{path: '/', query: $route.query}"
        class="back">{{currentLangDict.home}}</router-link>
      
    </nav>

    <section id="subtopics">
      <h4 class="subtopheader">{{currentLangDict.subtopics}}</h4>
      <div id="loading" v-if="loadingTopics">
        <img src="img/loading_icon.png"/>
      </div>
      <transition-group name="subtopics" tag="div">
        <figure v-for="(v, k, i) in subtopics"
          :key="i+1"
          :class="{'subtopic-chart': true, active: k === sub_topic}">
          <!-- <div @click="isTouch || isMobile ? selectSubTopic(k) : null"
            @mouseover="isTouch || isMobile ? null : selectSubTopicWait(k)"> -->
            <div @click="isTouch || isMobile 
              ? selectSubTopic(k) 
              : selectSubTopicWait(k)">
            <h3>
              <a v-if="k === sub_topic"
                role="button"
                title="Скачати інфографіку"
                @click="downloadedPng = k">
                <img src="img/download_white.svg">
              </a>{{ cl2name[k] }}
            </h3>
            <chart :daily="v"
              :slug="k"
              :days="days"
              :tooltip="k === sub_topic && !isTouch"
              :scaled="k === sub_topic"
              :maxY="maxY"
              @scroll-headlines="scrollHeadlines"/>
            <getPng v-if="downloadedPng === k"
              :daily="v"
              :slug="k"
              :days="days"
              :scaled="k === sub_topic"
              :title="cl2name[k]"
              :maxY="maxY"
              v-on:pngready="downloadedPng = null"/>
          </div>

          <transition name="dropdown" v-if="isMobile & k === sub_topic">
            <headlines :subtopic="sub_topic"
              :sitetypes="sitetypes"
              :from="from"
              :to="to"
              :isMobile="isMobile"/>
          </transition>
        </figure>
      </transition-group>
    </section>

    <div id="headline-feed-container" v-if="!isMobile">
      <h4 class="subtopheader">{{currentLangDict.newsHeadlines}}</h4>
      <headlines :subtopic="sub_topic"
        :sitetypes="sitetypes"
        :from="from"
        :to="to"
        :isMobile="isMobile"
        :scrollToDate="scrollToDate"/>
    </div>
  </article>
</section>

<Methdology/>
<TextyFooter/>

</div>
</template>

<script>
import * as d3 from 'd3';

import Methdology from './Methodology.vue';
import TextyFooter from './TextyFooter.vue';

export default {
  name: 'Subtopic',
  components: { TextyFooter, Methdology },
  props: {
    sub_slug: String,
    days: Array,
    sub_topic: String,
  },
  metaInfo() {
      return this.$store.getters.headerMeta
  },
  data() {
    return {
      subtopics: {},
      publicPath: process.env.BASE_URL,
      showOtherTopics: false,
      metatopic: [],
      loadingTopics: true,
      downloadedPng: null,
      pngURI: '#',
      scrollToDate: null,
      tranlationEng: {
        "newsHeadlines": "News Headlines",
        "topic": "Topic",
        "subtopics": "Subtopics", 
        "binDescr": " The bar height shows % of the topic among all the news of the day in a group of sites",
        "home": "Home",
        "period": "select a period"
      },
      translationUK: {
        "newsHeadlines": "Заголовки новин",
        "topic": "Тема",
        "subtopics": "Підтема",
        "binDescr": "Висота стовпчика показує % теми серед всіх матеріалів за день",
        "home": "На головну",
        "period": "Оберіть період"

      },    
    };
  },

  watch: {
    sub_slug(new_slug) {
      this.subtopics = {};
      this.loadingTopics = true;
      this.loadData(new_slug);
    },
    '$route.query.sitetypes': 'checkRouteUpd',
  },


  computed: {
    currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
    },
    meta2name() {
      return this.$store.getters.meta2name
    },
    cl2name() {
      return this.$store.getters.cl2name
    },
    isMobile() {
      return window.innerWidth < 768;
    },

    isTouch() {
      if ('ontouchstart' in window || window.DocumentTouch) {
        return true;
      }
      const q = '(touch-enabled),(-webkit-touch-enabled),(-moz-touch-enabled),(-o-touch-enabled),(-ms-touch-enabled),(heartz)';

      return window.matchMedia(q).matches;
    },

    sitetypes() {
      const sts = this.$route.query.sitetypes;
      return sts ? sts.split(',') : ['ru_for_uk_man'];
    },

    maxY() {
      const that = this;
      // знайти найбільше значення теми серед усіх метатем, щоб підібрати межу для графіка
      // домножуємо в кінці на 100, щоб перевести у відсотки

      const m = Math.max(...that.metatopic.map((d) => d.perc)) * 100;

      // ми спершу ділимо відсотки на 5, заокруглуємо до більшого і знову множимо на 5. Щоб отримати найближче число кратне 5
      // Далі ми ділимо на 100, щоб перевести з відсотків в фракцію
      return Math.ceil(m / 5) * 5 / 100

    },


    from() {
      const qd = this.$route.query.from;
      return qd
        ? new Date(qd)
        : this.pdate(this.days[this.days.length - 35]);
    },

    to() {
      const qd = this.$route.query.to;
      return qd
        ? new Date(qd)
        : this.pdate(this.days[this.days.length - 1]);
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData(subslug = this.sub_slug) {
      const that = this;
      Promise.all([
        d3.json(`${that.publicPath}new_data/subtopics/${subslug}.json`),
        d3.json(`${that.publicPath}new_data/daily_counts.json`)
      ]).then((files) => {
        that.metatopic =  files[1][that.sub_slug];
        // delete files[0].metatopic
        that.subtopics = files[0];
        // if (files.length === 2) {
        //   that.metatopic = that.metatopic.concat(files[1].metatopic);
        //   delete files[1].metatopic;
          
        //   Object.entries(files[1]).map(([k, v]) => {
        //     that.subtopics[k] = that.subtopics[k] ? that.subtopics[k].concat(v) : v;
        //   });
        // }
        that.loadingTopics = false;
      });
    },

    scrollMethodology() {
      document.querySelector('#methodology').scrollIntoView({ behavior: 'smooth' });
    },

    formatTime(t) {
      return d3.timeFormat('%d.%m.%y')(new Date(t));
    },

    substractWeek(date) {
      date = new Date(date);
      date.setDate(date.getDate() - 7);
      return date;
    },

    selectSubTopic(subt) {
      this.scrollToDate = null;
      if (this.sub_topic === subt) {
        this.$router.replace({
          path: `/${this.sub_slug}`,
          query: this.$route.query,
          props: { sub_slug: this.sub_slug },
        });
      } else {
        this.$router.replace({
          path: `/${this.sub_slug}/${subt}`,
          query: this.$route.query,
        });
      }
    },

    selectSubTopicWait(subt) {
      if (subt === this.sub_topic) return;
      this.selectSubTopic(subt);
    },

    checkRouteUpd() {
      this.subtopics = {};
      this.metatopic = [];
      this.loadingTopics = true;
      this.loadData();
    },

    pdate(d) {
      return d3.timeParse('%d.%m.%y')(d);
    },

    fdate(d) {
      return d3.timeFormat('%d.%m.%y')(d)
    },

    scrollHeadlines(e) {
      this.scrollToDate = e;
    },

    passQuery(q) {
      const that = this;
      that.$router.push({
        name: 'search',
        params: { q: q, isMobile: that.isMobile, },
        query: that.$route.query
      });
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/subtopic.scss';
</style>
