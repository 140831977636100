<template>
  <ol reversed :style="{'counter-reset': `li ${editions.length + 1}`}">
    <li v-for="(d, i) in editions" :key="i + 1">
      <a :href="d.link" target="_blank">{{ d.name }}</a>
      <span class="mon-period">{{ d.date }}</span>
    </li>
  </ol>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'Monitorings',
  data() {
    return {
      editions: [],
      // files are in ndi_topics_files folder on Google Drive
      tranlationEng: {
        "monFile": "https://docs.google.com/spreadsheets/d/e/2PACX-1vTYzsIxxKefU4Y5GNQddiz3ZOnREz5XjbU0-Am8ZM9TNnuzMVo2glqKpQxr3UP1UHA6yFhtCXLw5Ic0/pub?gid=196477981&single=true&output=tsv"
      },
      translationUK: {
        "monFile": "https://docs.google.com/spreadsheets/d/e/2PACX-1vRLAB2P_S333IUL8afA7jk3sJGvIf5LccBIg8DiaGqU9sPhPmypgWBbwqto-bLz2Q0AfoagEFqw5sQR/pub?gid=0&single=true&output=tsv"
      },    
    };
  },
  computed: { 
        currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
      },
      
  },
  created() {
    this.loadData();
  },
  watch: {
    'currentLangDict': 'loadData',
  },
  methods: {
    loadData() {
      const that = this;
      d3.tsv(that.currentLangDict.monFile).then(function(data) {
        that.editions = data;
      });
    },
  },
};
</script>
