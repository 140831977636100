<template>
  <form id="search" @submit="submitQ">
    <input v-model.trim="q" type="text" :placeholder="searchText"/>
    <button type="submit" title="Шукати">
      <img src="img/icon_search.svg"/>
    </button>
    <p v-if="err" class="error">{{ err }}</p>
    <p v-else></p>
  </form>
</template>

<script>
export default {
  name: 'searchmini',
  props: {
    preQ: { type: String, default: null },
    lang: {type: String}
  },
  data() {
    return {
      q: null,
      err: null,
    };
  },
  computed: {
    searchText: function() {
      const that = this;

      if (that.$store.state.lang == 'uk') {
          return "Пошук за матеріалами"
      }
      else {
        return "Search in database"
      }
    }
    
  },
  created() {
    this.q = this.preQ;
  },

  methods: {
    submitQ(e) {
      e.preventDefault();
      const that = this;
      if (that.q.length < 3 || !that.q.match(/[А-яA-z]/)) {
        that.err = 'Будь ласка, введіть хоча б 3 літери'
      } else {
        that.err = null;
        that.$store.state.qD = that.q
        // that.$root.$refs.Search.passQuery(that.q)
        // that.$router.push({ name: 'search', params: { q: that.q } })

        if (that.$route.fullPath != "/search"){
          // that.$store.state.qD = that.q
          that.$router.push({ name: 'search', params: { q: that.q } })
        }
        else{ 
          that.$store.state.qD = that.q
        }


      }      
    },
    removeErr(q) {
      if ( this.err && q.length >= 3 && q.match(/[А-яA-z]/) ) {
        this.err = null;
      };
    },

    passPreQ(q) {
      this.q = q;
    }
  },
  watch: {
    'q': 'removeErr',
    'preQ': 'passPreQ',
  }
};
</script>
