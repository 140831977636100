<template>
<transition name="dropdown">
  <div id="disclaimer" v-if="show">
    <p><label id="hide-disclaimer" for="hide-disclaimer-icon">
      <input type="checkbox" id="hide-disclaimer-icon" v-model="show">
      <svg viewBox="-1 -1 21 21">
        <path d="M0 0 L20 20 M0 20 L 20 0" vector-effect="non-scaling-stroke"/>
      </svg>
    </label></p>
    <p>{{currentLangDict.firstRow}}</p>
    <p>{{currentLangDict.secondRow}}</p>
    <p>{{currentLangDict.details[0]}}  <a href="#methodology" v-on:click="moveToMethdology()" class="link" role="button">{{currentLangDict.details[1]}}</a>.</p>
  </div>
</transition>
</template>

<script>
export default {
  name: 'Disclaimer',
  data() {
    return {
      show: true,
      listener() {
        const methodology = document.getElementById('methodology');
        if (methodology.getBoundingClientRect().y - window.innerHeight * 0.33 < 0) {
          document.querySelector('#hide-disclaimer').click();
        }
      },
      tranlationEng: {
        "firstRow": "The visualization shows an overall dynamics of topics of disinformation in news.",
        "secondRow": "Manipulative news and topics were determined by machine learning. Therefore, the data is not completely accurate but reflects a general trend.",
        "details": ["Look for details in", "methodology"]
      },
      translationUK: {
        "firstRow": "Графіки показують узагальнену динаміку тем російських новин з вибірки сайтів.",
        "secondRow": "Теми новин визначались за допомогою машинного навчання. Тому дані не є абсолютно точними, натомість вони відображають загальну тенденцію з певною похибкою",
        "details": ["Детальніше - у", "методології"]
      },    
    };
  },
  computed: { 
        currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
      },
      
  },
  methods: {
      moveToMethdology() {
        document.querySelector(event.target.getAttribute('to'))
        .scrollIntoView({behavior: 'smooth'});
      }
  },
  watch: {
    show() {
      window.removeEventListener('scroll', this.listener);
      this.$destroy;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.listener);
  },
};
</script>
