import 'es6-promise/auto';
import Vue from 'vue';
import Vuex from 'vuex'
import VueRouter from 'vue-router';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueMeta from 'vue-meta'


import days from '@/assets/new_data/days.json';

import cl2name from '@/assets/new_data/clusters.json';
import cl2nameEng from '@/assets/new_data/clusters_eng.json';

import meta2name from '@/assets/new_data/metatopics.json';
import meta2nameEng from '@/assets/new_data/metatopics_eng.json';





import Home from './Home.vue';
import Chart from './Chart.vue';
import Header from './Header.vue';
import Subtopic from './Subtopic.vue';
import Disclaimer from './Disclaimer.vue';
import GetPng from './GetPng.vue';
import Sitetype from './Sitetype.vue';
import Headlines from './Headlines.vue';
import Monitorings from './Monitorings.vue';
import Search from './Search.vue';
import SearchMini from './SearchMini.vue';
import HomeHeader from './HomeHeader.vue';
import Methodology from './Methodology.vue';
import TextyFooter from './TextyFooter.vue';







// eslint-disable-next-line
import font from '@/assets/scss/fonts.scss';
// eslint-disable-next-line
console.log(font);

Vue.config.productionTip = true;
Vue.use(VueRouter);

Vue.component('chart', Chart);
Vue.component('header-time', Header);
Vue.component('subtopic', Subtopic);
Vue.component('disclaimer', Disclaimer);
Vue.component('getPng', GetPng);
Vue.component('sitetype', Sitetype);
Vue.component('headlines', Headlines);
Vue.component('monitorings', Monitorings);
Vue.component('search', Search);
Vue.component('searchmini', SearchMini);
Vue.component('tippy', TippyComponent);
Vue.component('homeheader', HomeHeader);
Vue.component('methodology', Methodology);
Vue.component('textyfooter', TextyFooter);





Vue.use(VueTippy, {
  directive: 'tippy',
  flipDuration: 0,
});


const engVersionLink = true;


const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: { days },
 
    },
    {
      path: '/eng',
      name: 'home',
      component: Home,
      props: { days,engVersionLink },
 
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      props: function(route) {
        return {...route.params, days};
      },
    },
    {
      path: '/:sub_slug',
      component: Subtopic,
      props: (route) => ({
        sub_slug: route.path.replace('/', ''),
        days,      
      }),
    },
    {
      path: '/:sub_slug/:sub_topic',
      component: Subtopic,
      props: (route) => ({
        sub_slug: route.path.match(/^\/([a-z\-0-9_]+)/)[1],
        sub_topic: route.path.match(/\/([a-z\-0-9_]+)\/?$/)[1],
        days,
      }),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return (from.path === '/' && to.path !== '/')
      || (from.path !== '/' && to.path === '/')
      || (from.params.sub_topic && !to.params.sub_topic)
      ? { selector: 'form#search, #time-selector' }
      : savedPosition;
  },
});


Vue.use(Vuex)
Vue.use(VueMeta)


const store = new Vuex.Store({
  state: {
    lang: "uk",
    anotherState: "eng",
    qD: "",
    dateRange: {"from":"", "to": ""},
    meta: { 
      title: "Деза Війни - ТЕКСТИ",
      meta: [
          { property: 'og:site_name', content: "ТЕКСТИ.ORG.UA"},
          { property: 'og:type', content: 'website'},
          { property: 'og:title', content: 'Деза Війни'},    
          { name: 'og:image', content: 'https://topic-radar.texty.org.ua/img/cover3.png'},
          { property: 'og:description', content: 'Динаміка тем російської дезінформації: про що пишуть російські ЗМІ'},    
          { name: 'og:url', content: 'https://topic-radar.texty.org.ua'},

          { property: 'twitter:site', content: "@textyorgua"},
          { property: 'twitter:account_id', content: '49572937'},
          { property: 'twitter:card', content: 'summary_large_image'},    
          { name: 'twitter:url', content: 'https://topic-radar.texty.org.ua'},
          { property: 'twitter:title', content: "Деза Війни"},
          { property: 'twitter:description', content: 'Динаміка тем російської дезінформації: про що пишуть російські ЗМІ'},
          { property: 'twitter:image:src', content: 'https://topic-radar.texty.org.ua/img/cover3.png'},    
          { name: 'twitter:image:width', content: '1200'},
          { property: 'twitter:image:height', content: '630'},    
      ]
  },
    metaENG: { 
      title: "Disinformation about War - TEXTY",
      meta: [
        { property: 'og:site_name', content: "TEXTY.ORG.UA"},
        { property: 'og:type', content: 'website'},
        { property: 'og:title', content: 'Disinformation about War'},    
        { name: 'og:image', content: 'https://topic-radar.texty.org.ua/img/cover3.png'},
        { property: 'og:description', content: 'Dynamics of topics of Russian disinformation: what do Russian media write'},    
        { name: 'og:url', content: 'https://topic-radar.texty.org.ua'},

        { property: 'twitter:site', content: "@textyorgua"},
        { property: 'twitter:account_id', content: '49572937'},
        { property: 'twitter:card', content: 'summary_large_image'},    
        { name: 'twitter:url', content: 'https://topic-radar.texty.org.ua'},
        { property: 'twitter:title', content: "Disinformation about War"},
        { property: 'twitter:description', content: 'Dynamics of topics of Russian disinformation: what do Russian media write'},
        { property: 'twitter:image:src', content: 'https://topic-radar.texty.org.ua/img/cover3.png'},    
        { name: 'twitter:image:width', content: '1200'},
        { property: 'twitter:image:height', content: '630'},    
      ]
  },

  },
  getters: {
    meta2name(state) {
      if (state.lang =="uk") {
        return meta2name
      } 
      else {
        return meta2nameEng
      }
    },
    cl2name(state) {
      if (state.lang =="uk") {
        return cl2name
      } 
      else {
        return cl2nameEng
      }
    },
    headerMeta(state) {
      if (state.lang =="uk") {
        return state.meta
      } 
      else {
        return state.metaENG
      }
    },
  },
  mutations: {
    changeLang (state) {
      let lang = state.lang
      let anotherState = state.anotherState
      
      state.lang = anotherState
      state.anotherState = lang

    },

    changeDateRange(state, dates) {
      state.dateRange = dates
    }


  }
})


new Vue({
  router,
  store
}).$mount('main');




document.querySelectorAll('header a.menu-link, #disclaimer .link, #hline-disclaimer .link')
.forEach(function(el) {
  el.addEventListener("click", function() {
    document.querySelector(el.getAttribute('to'))
    .scrollIntoView({behavior: 'smooth'});
  })
})

