<template>
  <svg ref="svg" :width="svgW" :height="svgH" :class="{sized: graphsSized, chart: true}">
    <g v-if="axisComp" class="y-axis">
      <line x1="-7" x2="-7" y1="0" :y2="svgH" stroke-width="0.5px"/>
      <!-- <line x1="-10" x2="-4" :y1="svgH/2" :y2="svgH/2"/> -->
      
      <text x="-13" :y="svgH" class="lab-zero">0</text>
      <text x="-13" y="0" class="lab-top">
        {{ scaleYLab }}
      </text>
      <!-- <text x="-13" :y="svgH/2" class="lab-bottom">
        {{ scaleYLab }}
      </text> -->
    </g>

    <g v-if="annotate" class="annotation">
      <text x="0" :y="svgH + 20" class="axis-lab">{{ currentLangDict.selectPeriod }} {{ fromF }} — {{ toF }} </text>
    </g>

    <line v-if="tipX && tipTop" class="tip-line" vector-effect="non-scaling-stroke"
      :x1="tipX" :x2="tipX" y1="-5" :y2="svgH"
      :style="{stroke: '#FF4128'}"
    />

    <rect v-for="(b, i) in bars" :key="i"
      :x="b.x"
      :y="b.y"
      :width="b.w"
      :height="b.h"
      :class="b.sitetype"
      :style="{fill: '#FF4128'}"
    />
    <!-- Cхоже це штука яка робить tip, тобто лінію яка показує на який день ти дивишся і яке там значення -->
    <rect x="0" y="0"
      :height="svgH"
      :width="svgW"
      opacity="0"
      v-if="tooltip"
      :name="slug"
      @mousemove="showTip"
      @mouseout="tipX = null"
      @click="scrollHeadlines"
    />

    <tippy v-if="tooltip && graphsSized" delay="500" :to="slug" followCursor="horizontal" trigger="mouseenter" distance="-16" placement="top" sticky="false" flip="false" hideOnClick="false" multiple
      :content="tipTop"
    />
    <tippy v-if="tooltip && graphsSized" delay="500" :to="slug" followCursor="horizontal" trigger="mouseenter" distance="-16" placement="bottom" sticky="false" flip="false" hideOnClick="false" multiple
      :content="tipBtm"
    />
  </svg>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'Chart',
  props: {
    daily: Array,
    slug: String,
    days: Array,
    axis: {default: false, type: Boolean},
    i: {default: null, type: Number},
    annotate: {default: false, type: Boolean},
    tooltip: {default: false, type: Boolean},
    scaled: {default: false, type: Boolean},
    maxY: {default: 0.05, type: Number},
  },
  data() {
    return {
      svgW: 0,
      svgH: 0,
      hoverRect: null,
      tipX: null,
      tipTop: null,
      tipBtm: null,
      graphsSized: false,
      types: {
        uk_man: 'Українські маніпулятивні',
        ru_for_uk_man: 'РосЗМІ для України, маніпулятивні',
        reg: 'Локальні українські видання, всі новини',
        uk: 'Провідні українські сайти, всі матеріали',
        ru: 'Провідні російські сайти, всі матеріали',
        tg: 'Популярні телеграм канали, всі матеріали',

      },
      color: '#FF4128',
        tranlationEng: {
          "selectPeriod": "Selected period:",
          "date": "Date:",
          "fraction": "% of topic mentions among all news articles:",
          "numberOfNews": "Number of articles:"          
      },
      translationUK: {
          "selectPeriod": "Обраний період:",
          "date": "Дата:",
          "fraction": "% теми серед всіх матеріалів з групи:",
          "numberOfNews": "К-ть новин:"

        },    
    }
  },

  mounted() {
    const that = this;
    this.setSvgDims();
    window.addEventListener('resize', that.setSvgDims);
  },

  computed: {
    currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
      },
      
    sitetypes: function() {
      const sts = this.$route.query.sitetypes;
      return sts ? sts.split(',') : [ 'ru_for_uk_man'];
    },
    
    from: function() {
      const qFrom = this.$route.query.from;
      return qFrom ? new Date(qFrom) : this.pdate(this.days[this.days.length - 30])
    },

    to: function() {
      const qTo = this.$route.query.to;
      return qTo ? new Date(qTo) : this.pdate(this.days[this.days.length - 1])
    },

    filteredDaily: function() {
      const that = this;
      return that.daily
        .filter(d => new Date(d.date) >= that.from && new Date(d.date) <= that.to)
    },

    toF: function() {
      return this.fdate(this.to);
    },

    fromF: function() {
      return this.fdate(this.from);
    },

    selectedDays: function() {
      const that = this;
      return that.days
        .map(that.pdate)
        .filter(d => d >= that.from && d <= that.to)
        .sort((a, b) => a < b ? -1 : 1)
        .map(that.fdate)
    },

    scaleX: function() {
      const that = this;
      const pad = d3.min([
        0.3333,
        (that.svgW / that.selectedDays.length) / 15
      ]);

      return d3.scaleBand()
        .domain(that.selectedDays)
        .range([0, that.svgW])
        .paddingInner(pad);
    },

    scaleY: function() {
      const that = this;
      return d3.scaleLinear()
        .domain([0, that.maxY])
        .range([0, that.svgH]);
    },

    scaleYLab: function() {
      const ymax = this.scaleY.domain()[1];
      return (ymax > 0 && ymax < 100) ? `${(ymax * 100).toString().split('.')[0]}%` : '…'
    },

    bars: function() {
      const that = this;

      return that.filteredDaily
        .map(function(d) {
          const y_abs = that.scaleY(d.perc);
          return {
            x: that.scaleX(that.fdate(new Date(d.date))),
            // y: that.sitetypes.indexOf(d.sitetype) ? that.svgH / 2 : that.svgH / 2 - y_abs,
            y: that.svgH - y_abs, 
            h: y_abs,
            w: that.scaleX.bandwidth(),
            n_day: d.n,
            sitetype: d.sitetype,
            sitetype_r: that.types[d.sitetype],
          }
      })
    },

    axisComp: function() {
      if (this.axis) return true;
      if (this.i == null) return false;
      const w = window.innerWidth;
      if (w < 768) return true;
      if (w >= 992  && this.i % 3 === 0) return true
      if (w >= 768  && w < 992 && this.i % 2 === 0) return true
      return false;
    }
  },

  methods: {
    fdate(dt) {
      return d3.timeFormat('%d.%m.%y')(dt)
    },
    pdate(s) {
      return d3.timeParse('%d.%m.%y')(s)
    },
    setSvgDims() {
      const svg = this.$refs.svg;
      if (!svg) {
        return
      }
      const coef = this.graphsSized && this.scaled
        ? window.innerWidth < 768 ? 2 : 1.5
        : 1;

      this.svgW = (svg.offsetWidth || svg.getBoundingClientRect().width || svg.clientWidth) / coef;
      // this.svgW = svg.getBoundingClientRect().width * 0.9
      this.svgH = 100//(svg.offsetHeight || svg.getBoundingClientRect().height || svg.clientHeight) / coef;
      this.graphsSized = true;
    },

//  style="background-color: #353535; color: white;
    showTip(event) {
      const that = this;
      const bcr = that.$el.getBoundingClientRect();
      const[w, left] = [bcr.width, bcr.x];
      const coef = that.scaled ? 1.7 : 1;
      const x = (event.x - left);
      let day = Math.round(x / w * that.selectedDays.length);
      day = that.selectedDays[day];
      const selDay = that.daily.filter(d => that.fdate(new Date(d.date)) === day);
      const topD = selDay
      that.tipX = x / coef;
      that.tipTop = topD.length === 0
        ? null
        : `
        <div class="tip tip-top" style="background-color: #353535; color: #D9D9D9; border: 0.5px solid #D9D9D9">
          <h6 style="color: #D9D9D9">${that.currentLangDict.date} ${that.fdate(new Date(topD[0].date))} </h6>
          <p>${that.currentLangDict.fraction} <span style="color: ${that.color}">${Math.round(topD[0].perc * 1000) / 10 + '%'}</span> </p>
          <p>${that.currentLangDict.numberOfNews} <span style="color: ${that.color}">${topD[0].n}</span>  </p>
        </div>
        `;


          // "selectPeriod": "Selected period:",
          // "date": "Date:",
          // "fraction": "% of topic mentions among all news articles:",
          // "numberOfNews": "Number of articles:"          


    },

// <span style="color: ${that.colors[st1]}">${that.types[st1]}</span>
 
    scrollHeadlines(event) {
      const that = this;
      const bcr = that.$el.getBoundingClientRect();
      const[w, left] = [bcr.width, bcr.x];
      const x = (event.x - left);
      let day = Math.round(x / w * that.selectedDays.length);
      day = that.selectedDays[day];
      that.$emit('scroll-headlines', day)
    }
  }
}
</script>
