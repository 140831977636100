<template>
<div>

<head>
    <meta property="og:site_name" content="TEXTY.ORG.UA"/>
</head>
<slot></slot>

<section id="vis">

  <div class="header-side">
    <p> {{ currentLangDict.selectPeriod }} </p>
  </div>
  <header-time :days="days"/>
  <div class="header-side"></div>

  <!-- <sitetype/> -->

  <p class="legend"> {{ currentLangDict.chartDescription }} </p>

  <article id="topic-view">
    <div id="loading" v-if="loadingTopics">
      <img src="img/loading_icon.png"/>
    </div>
    <router-link v-for="(v, k, i) in topics" :key="i"
      :to="{path: '/' + k, query: $route.query}"
      tag="div"
      class="metatopic-chart"
    >
      <h3 class="h-metatopic">{{ meta2name[k] }}</h3>
      <figure>
        <chart :daily="v"
          :slug="k"
          :days="days"
          :i="i"
          :annotate="i === 0"
          :maxY="maxY"
        />
      </figure>
    </router-link>
  </article>
 
</section>

<Methdology/>
<Disclaimer/>

<TextyFooter/>
</div>
</template>

<script>
import * as d3 from 'd3';
import { mapMutations } from 'vuex'
import Methdology from './Methodology.vue';
import TextyFooter from './TextyFooter.vue';
import Disclaimer from './Disclaimer.vue';


export default {
  components: { TextyFooter, Methdology, Disclaimer },
  name: 'Home',
  metaInfo() {
      return this.$store.getters.headerMeta
  },
  data() {
    return {
      topics: {},
      publicPath: process.env.BASE_URL,
      loadingTopics: true,
      tranlationEng: { 
          "chartDescription": "The bar height shows % of the topic among all the news of the day in a group of sites",
          "selectPeriod": "Select period",

          },
        translationUK: {
          "chartDescription": "Висота стовпчика показує % теми серед всіх матеріалів за день",
          "selectPeriod": "Оберіть період",
        },          
    };
  },
  props: {
    days: Array,
    engVersionLink: Boolean 
  },

  created() {
    this.loadData(this.sitetypes);

    if (this.engVersionLink) {
      this.$store.commit('changeLang')
    } 
  },

  mounded() {
    document.querySelectorAll('a[to]')
      .forEach((el) => {
        el.addEventListener('click', (e) => {
          document.querySelector(e.target.getAttribute('to'))
            .scrollIntoView({ behavior: 'smooth' });
        });
      });
  },
  computed: {
    currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
    },
    meta2name() {
      return this.$store.getters.meta2name
    },
    sitetypes() {
      const sts = this.$route.query.sitetypes;
      return sts ? sts.split(',') : ['ru_for_uk_man'];
    },

    maxY() {
      const that = this;
      // знайти найбільше значення теми серед усіх метатем, щоб підібрати межу для графіка
      // домножуємо в кінці на 100, щоб перевести у відсотки
      const m = d3.max(Object.values(that.topics), (d) => d3.max(d, (v) => v.perc)) * 100;

      // ми спершу ділимо відсотки на 5, заокруглуємо до більшого і знову множимо на 5. Щоб отримати найближче число кратне 5
      // Далі ми ділимо на 100, щоб перевести з відсотків в фракцію
      return Math.ceil(m / 5) * 5 / 100
    },
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  methods: {
    ...mapMutations([
      'changeLang',
    ]),
    changeLang(){
      this.$store.commit('changeLang')
    },
    loadData(st) {
      const that = this;
      Promise.all(
        [...st.map((n) => d3.json(`${that.publicPath}new_data/daily_counts.json`))],
      ).then((files) => {
        that.topics = files[0];
        if (files.length === 2) {
          Object.entries(files[1]).map(([k, v]) => {
            that.topics[k] = that.topics[k] ? that.topics[k].concat(v) : v;
          });
        }
        that.loadingTopics = false;
      });
    },

    checkStUpd() {
      this.topics = {};
      this.loadingTopics = true;
      console.log(this.sitetypes);
      this.loadData(this.sitetypes);
    },

    passQuery(q) {
      const that = this;
      that.$router.push({
        name: 'search',
        params: { q: q, isMobile: that.isMobile, },
        query: that.$route.query
      });
    },
  },
  watch: {
    '$route.query.sitetypes': 'checkStUpd',
  },
};
</script>

<style lang="scss">
@import './assets/scss/home.scss';
</style>
