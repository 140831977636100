<template>
    <header>
      <div id="links">
        <a class="menu-link" id="lang" v-on:click="changeLang()">{{ currentLangDict.otherLang }}</a>
        <a class="menu-link" id="old" href="https://fgz.texty.org/static/pre_war_dashboard/index.html#/">{{ currentLangDict.oldDashboard }}</a>  
      </div>
      <div id="title">
        <h1><a href="https://topic-radar.texty.org.ua">{{ currentLangDict.title }}</a></h1>
        <h2>  {{ currentLangDict.subTitle }}  <span class="version">  {{ currentLangDict.version }} </span></h2>
      </div>
      <searchmini></searchmini>
      <div id="menu-links">
        <a role="button" @click="moveToMethdology()" to="#methodology-editions" class="menu-link desktop"> {{ currentLangDict.methodologyTitle }} </a>
        <!-- <a role="button" to="#editions" class="menu-link mob">weekly monitorings</a>
        <a role="button" to="#methodology" class="menu-link mob">methodology</a> -->
      </div>
      <div id="texty-social">
        <a href="https://texty.org.ua" target="_blank" id="texty-logo">
          <img src="img/texty_logo.svg">
        </a>
        <a href="https://www.facebook.com/sharer/sharer.php?u=https://topic-radar.texty.org.ua"><img src="img/fb.svg" class="social fb"></a>
        <a href="https://twitter.com/intent/tweet?text=https://topic-radar.texty.org.ua"><img src="img/twitter.svg" class="social twitter"></a>
      </div>
    </header>
</template>

<script>

import { mapMutations } from 'vuex'


export default {
  name: "HomeHeader",
  methods: {
    ...mapMutations([
      'changeLang',
    ]),
    changeLang(){
      this.$store.commit('changeLang')
    },
    moveToMethdology() {
        document.querySelector(event.target.getAttribute('to'))
        .scrollIntoView({behavior: 'smooth'});
    }
  },
  data() {
    return {
        tranlationEng: {
          "oldDashboard": "Old Dashboard",
          "title": "Wartime Disinfo from Russia",
          "version": "Version 3",
          "otherLang": "Українська",
          "subTitle": "Dynamics of Russian disinformation",
          "methodologyTitle": "weekly monitorings and methodology",
          },
        translationUK: {
          "oldDashboard": "Старий дашборд",
          "title": "Деза Війни",
          "version": "Версія 3",
          "otherLang": "English",
          "subTitle": "Динаміка тем російської дезінформації: про що пишуть російські ЗМІ",
          "methodologyTitle": "щотижневі моніторинги і методологія",
          },          
      }
    },
  computed: {
        currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
      },
      
    },
}
</script>

// <style lang="scss">
// // @import './assets/scss/header.scss';
// </style>

