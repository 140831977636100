<template>
    <article id="methodology-editions">
        <section id="editions">
          <div>
          <h3>{{currentLangDict.title}}</h3>
          <monitorings/>
        </div>
        </section>

        <section id="methodology" v-html="currentLangDict.html"></section>

        
    </article>
    
</template>

<script>

import { mapMutations } from 'vuex'

export default {
  name: "Methodology",
  methods: {
    ...mapMutations([
      'changeLang',
    ]),
    changeLang(){
      this.$store.commit('changeLang')
    }
  },
  data() {
    return {
        a: 5,
        tranlationEng: {
            "html": `
                <h3>Methodology</h3>
                <h4>Data collection and processing</h4>
                <p>We began downloading publications on the mainstream Russian news media and Russian media targeting Ukraine since the start of the Russo-Ukrainian war (Feb 2022).</p>
                <p>We use website RSS feeds to collect our data. Each downloaded news piece is attributed with the date of publication, URL, headline and a full-text sequence. After the download, our model splits each text sequence into separate paragraphs. The model then discards text paragraphs that do not contain meaningful content – i.e. asks to subscribe or highlight mistakes on the website.</p>

                <h4>Topic detection</h4>
                <p>Automatic topic detection of individual paragraphs is done using the <a href="https://maartengr.github.io/BERTopic/index.html" target="_blank">BERTopic</a> technique. The model produces a set of paragraph clusters (groups), which are then classified manually. At the classification stage, we first discard paragraph clusters that do not contain any information on the war or its consequences for Russia (e.g. coronavirus, sport, weather, pop stars, car accidents etc.). Then, we group the remaining clusters into topics and sub-topics, as seen on the dashboard.</p>
                <p>We detect topics in groups of individual paragraphs, not the entire news pieces. <strong>Because of this, some news pieces may appear under several dashboard topics simultaneously.</strong>.</p>

                <h4>Web site sample:</h4>
                <ul>
                    <li>72 Russian websites targeting Ukraine: <em>3652.ru, 3654.ru, 8692.ru, anna-news.info, antifashist.com, antimaydan.info, c-inform.info, comitet.su, crisis.in.ua, delovoydonbass.ru, dnr-lnr.info, dnr-pravda.ru, dnr24.com, dnr24.su, donbasstoday.ru, doneck-news.com, dontimes.news, dontimes.ru, dosie.su, e-gorlovka.com.ua, e-news.su, evening-crimea.com, free-news.su, fresh.org.ua, fromdonetsk.net, front-novorossii.ru, gorlovka.today, jankoy.org.ua, kafanews.com, komtv.org, kv-journal.su, lgt.su, luga1news.ru, lugansk1.info, meridian.in.ua, metayogg.com, miaistok.su, mir-lug.info, mnyug.com, mozaika.dn.ua, nahnews.org, naspravdi.info, nefakt.info, newc.info, news-front.info, newsland.com, nk.org.ua, novorosinform.org, novorossiy.info, novosti.icu, odnarodyna.org, on-line.lg.ua, patriot-donetsk.ru, pohnews.org, politnavigator.net, politobzor.net, pravdanews.info, ruinformer.com, rusdnepr.ru, rusnext.ru, russian-vesna.ru, rusvesna.su, sevastopol.su, sevnews.info, sobytiya.info, svodki24.ru, time-news.net, ukraina.ru, voenkor.info, voskhodinfo.su, vsednr.ru, xvesti.ru</em></li>
                    <li>16 mainstream Russian media: <em>aif.ru, dni.ru, kommersant.ru, kp.ru, lenta.ru, lentainform.com, life.ru, newsru.com, pravda.ru, regnum.ru, riafan.ru, russian.rt.com, slovodel.com, svpressa.ru, tass.ru, vz.ru</em></li>
                </ul>
            `,
            "title": "Monitoring of Russian disinformation"
          },
        translationUK: {
        "html": `
        <h3>Методологія</h3>
        
				<h4>Збір та обробка даних</h4>
        <p>Ми збирали всі новини, що були опубліковані після початку війни в Україні, з вибірки провідних російських сайтів та російських сайтів, орієнтованих на українську тематику.</p>
        <p>Дані завантажували з RSS стрічок сайтів. Для кожної новини завантажили дату публікації, лінк, заголовок та повний текст. Кожен текст розбили на абзаци та видалили абзаци, що не мають змістовного навантаження (наприклад, пропонують виділити знайдені помилки чи підписатись на соціальні мережі видань)</p>
        
				<h4>Як визначали теми</h4>
        <p>Всі абзаци новин ми розбили на теми за допомогою автоматичного моделювання тем (<a href="https://maartengr.github.io/BERTopic/index.html" target="_blank">BERTopic</a>). Отримані кластери (групи) абзаців редагували вручну: відкинули ті, що не стосуються війни або її наслідків для Росії (коронавірус, спорт, погода, поп-зірки, ДТП тощо) та виокремили теми (й підтеми), представлені в дашборді.</p>
        <p>Оскільки ми класифікуємо не повні тексти новин, а окремі абзаци, <strong>одна новина може містити одночасно декілька тем</strong>.</p>

				<h4>Сайти моніторингу</h4>
        <ul>
          <li>72 російські сайти, орієнтовані на українську тематику: <em>3652.ru, 3654.ru, 8692.ru, anna-news.info, antifashist.com, antimaydan.info, c-inform.info, comitet.su, crisis.in.ua, delovoydonbass.ru, dnr-lnr.info, dnr-pravda.ru, dnr24.com, dnr24.su, donbasstoday.ru, doneck-news.com, dontimes.news, dontimes.ru, dosie.su, e-gorlovka.com.ua, e-news.su, evening-crimea.com, free-news.su, fresh.org.ua, fromdonetsk.net, front-novorossii.ru, gorlovka.today, jankoy.org.ua, kafanews.com, komtv.org, kv-journal.su, lgt.su, luga1news.ru, lugansk1.info, meridian.in.ua, metayogg.com, miaistok.su, mir-lug.info, mnyug.com, mozaika.dn.ua, nahnews.org, naspravdi.info, nefakt.info, newc.info, news-front.info, newsland.com, nk.org.ua, novorosinform.org, novorossiy.info, novosti.icu, odnarodyna.org, on-line.lg.ua, patriot-donetsk.ru, pohnews.org, politnavigator.net, politobzor.net, pravdanews.info, ruinformer.com, rusdnepr.ru, rusnext.ru, russian-vesna.ru, rusvesna.su, sevastopol.su, sevnews.info, sobytiya.info, svodki24.ru, time-news.net, ukraina.ru, voenkor.info, voskhodinfo.su, vsednr.ru, xvesti.ru</em></li>
          <li>16 великих російських видань: <em>aif.ru, dni.ru, kommersant.ru, kp.ru, lenta.ru, lentainform.com, life.ru, newsru.com, pravda.ru, regnum.ru, riafan.ru, russian.rt.com, slovodel.com, svpressa.ru, tass.ru, vz.ru</em></li>
				</ul>
            `, 
            "title": "Моніторинги російської дезінформації"
          },          
      }
    },
  computed: {
        currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
      },
      
    }
}
</script>

// <style lang="scss">
// // @import './assets/scss/header.scss';
// </style>

