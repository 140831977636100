<template>
<nav id="site-selector">
  <label v-if="!no_toggle" for="toggle-site-dropdown">
    Оберіть типи сайтів <img :src="`img/${show ? 'close' : 'arrow_down'}.svg`"/>
  </label>
  <input v-if="!no_toggle" v-model="show" type="checkbox" id="toggle-site-dropdown">
  <transition name="sitetype">
  <div id="site-dropdown" v-if="show || no_toggle">
    <div v-for="(v, k) in types" :key="k" class="site-option"
      :style="{
          'background-color': selected.indexOf(k) > -1 ? colors[k] : null,
          'border-color': colors[k],
      }">
      <input v-model="selected" type="checkbox" @change="onSiteType($event)" :id="k" :value="k">
      <label :for="k">
        <span class="name">{{ v }}</span>
        <span class="toggle-sitetype"
          :style="{'color': selected.indexOf(k) < 0 ? colors[k] : 'white',}">
          {{ (selected.indexOf(k) > -1) ? '–' : '+' }}
        </span>
      </label>
    </div>
  </div>
  </transition>
</nav>
</template>


<script>
export default {
  name: 'Sitetype',
  data() {
    return {
      show: false,
      selected: ['ru_for_uk_man'],
      types: {
        uk_man: 'Українські маніпулятивні',
        ru_for_uk_man: 'РосЗМІ для України, маніпулятивні',
        reg: 'Локальні українські видання, всі матеріали',
        uk: 'Провідні українські сайти, всі матеріали',
        ru: 'Провідні російські сайти, всі матеріали',
        tg: "Популярні телеграм канали, всі матеріали"
      },
      colors: {
        uk_man:        '#ff007d',
        ru_for_uk_man: '#8c0061',
        reg:           '#f967c8',
        uk:            '#000000',
        ru:            '#7d7d7d',
        tg:            '#8d8d8d',
      },
    };
  },
  props: {
    no_toggle: { default: false, type: Boolean },
  },

  mounted() {
    const sts = this.$route.query.sitetypes;
    if (sts) { this.selected = sts.split(','); }
  },

  methods: {
    onSiteType() {
      const that = this;
      this.$nextTick(() => {
        if (that.selected.length === 3) {
          that.selected.shift();
        }
        if (that.selected.length === 0) {
          that.selected = ['uk_man', 'ru_for_uk_man'];
        }
        that.$router.replace({
          query: {
            from: that.$route.query.from,
            to: that.$route.query.to,
            sitetypes: that.selected.join(','),
          },
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/sitetype.scss';
</style>
