<template>
<section id="headline-feed" v-if="!isMobile">
  <p v-if="!subtopic && !readyData" class="no-subtopic">
    Оберіть підтему, щоб переглянути заголовки
  </p>
  <p v-if="!subtopic && readyData && !readyData.length" class="no-subtopic">
    Введіть пошуковий запит, щоб переглянути заголовки
  </p>
  <transition-group v-if="compHeadlines.length"
    name="headlines" tag="div" id="headlines">
    <p key="-100" id="hline-disclaimer">{{currentLangDict.topics_automatic}} <span v-if="addAboutFullText">{{currentLangDict.full_text}} </span><br>{{currentLangDict.read_more}} <a to="#methodology" class="link" role="button" @click="scrollMethodology">{{currentLangDict.methodology}}</a></p>
    <div v-for="d in compHeadlines"
      :key="d.title + d.date + d.site"
      :data-date="d.date"
      ref="headlines">
      <p class="title">{{d.title}}</p>
      <p class="title-info">
        <span :class="{url_domain: true, linked: showLink && d.link}"
          :style="{color: colors[d.sitetype]}"
          :content='(showLink && d.link) ? `<p class="link-tip-p">Лінк на матеріал з сайту ${d.site}: <a href="${d.link}" target="_blank">${truncateString(d.link)}</a></p>` : null'
          v-tippy="{
            interactive : true,
            trigger: 'click',
            placement: 'bottom',
            theme: 'link-tip',
            distance: 5,
          }">
          {{ d.site }}
        </span>
        <span class="published"> | {{ fdate(d.date) }}</span>
      </p>
    </div>
  </transition-group>
  <!-- <tippy v-if="showLink" delay="500" to="url_domain" placement="top"
    :content="linkTip" :interactive="true"
  /> -->
</section>
<div v-else id="headline-feed-mob">
  <transition-group v-if="compHeadlines.length"
    name="headlines" tag="div" id="headlines">
    <p key="-100" id="hline-disclaimer">{{ currentLangDict.topics_automatic }} <span v-if="addAboutFullText">{{ currentLangDict.full_text }} </span><br>{{currentLangDict.read_more}} <a to="#methodology" class="link" role="button" @click="scrollMethodology">{{currentLangDict.methodology}}</a></p>
    <div v-for="d in compHeadlines" :key="d.news_id">
      <p class="title">{{d.title}}</p>
      <p class="title-info">
        <span class="url_domain" :style="{color: '#FF4128'}">{{ d.site }}</span>
        <span class="published"> | {{ fdate(d.date) }}</span>
      </p>
    </div>
  </transition-group>
</div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'Headlines',
  props: {
    subtopic: String,
    sitetypes: Array,
    from: Date,
    to: Date,
    isMobile: Boolean,
    readyData: {default: null, type: Array},
    scrollToDate: {default: null, type: String},
    addAboutFullText: {default: false, type: Boolean},
    showLink: {default: false, type: Boolean},
  },
  data() {
    return {
      headlines: [],
      publicPath: process.env.BASE_URL,
      colors: {
        ru_for_uk_man: '#FF4128',

      },
      linkTip: null,
        tranlationEng: {
          "topics_automatic": "Topics were identified automatically, so a small amount of material may be mistaken.",
          "full_text": "The search was carried out on the full text of the material.",
          "read_more": "To find out more, read ",
          "methodology": "methodology"
      },
        translationUK: {
          "topics_automatic": "Теми визначали автоматично, тому невелика частина матеріалів може відноситись до підтеми помилково.",
          "full_text": "Пошук здійснювався за повним текстом матеріалу.",
          "read_more": "Детальніше можна дізнатись з",
          "methodology": "методології"
      },   
    };
  },


  created() {
    if (this.subtopic) {
      this.loadData()
    }
    if (this.readyData) {
      this.headlines = this.readyData;
    }
  },

  beforeDestroy() {
    this.headlines = [];
  },

  computed: {
    currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
    },
    compHeadlines: function() {
      const that = this;
      return that.headlines.filter(function(d) {
        return d.date <= that.to && d.date >= that.from;
      })
    },
  },

  methods: {
    scrollMethodology() {
      document.querySelector('#methodology').scrollIntoView({behavior: 'smooth'});
    },

    loadData() {
      if (!this.subtopic) {
        return;
      }
      const that = this;
      Promise.all(
        [...that.sitetypes.map(
          n => d3.json(`${that.publicPath}new_data/headlines/${that.subtopic}.json`)
        )]
      ).then(function(files) {
        that.headlines = files[0].concat(...files.slice(1)
          ).map(function(d) {
            d.date = that.dparseISO(d.date)
            return d;
          })
          .sort(function(a, b) {
            return a.date > b.date ? -1 : 1;
          })
      });
    },

    dparseISO(d) {
      return new Date(d);
    },

    fdate(d) {
      return d3.timeFormat('%d.%m.%y')(this.dparseISO(d));
    },

    scrollHeadlines(dNew) {
      if (!dNew) {
        this.$el.scrollTop = 0
        return
      }
      if (!this.$refs.headlines) {
        return
      }
      const dt = d3.timeParse('%d.%m.%y')(dNew)
      const matchHeadline = this.$refs.headlines
        .find(d => new Date(d.getAttribute('data-date')) <= dt)

      if (matchHeadline) {
        this.$el.scrollTop = matchHeadline.offsetTop - 640
      }
    },

    updReadyData(dNew) {
      this.headlines = dNew;
    },

    truncateString(str, len=40) {
      return (str.length <= len) 
        ? str
        : str.slice(0, len-1) + '…'
    },
  },

  watch: {
    'subslug': 'loadData',
    'sitetypes': 'loadData',
    'scrollToDate': 'scrollHeadlines',
    'readyData': 'updReadyData',
  },
  
}
</script>