<template>
<div class="png">
  <figure>
  <svg ref="svg" :width="svgW" :height="svgH"
    :style="{background: '#353535'}">

    <g transform="translate(75 150)">
      <g class="y-axis">
        <line x1="-5" x2="-5" y1="0" :y2="gH" :style="{stroke: '#D9D9D9', 'stroke-width': '0.5px'}"/>
        <text x="-13" y="0" class="lab-top"
          :style="{'text-anchor': 'end', 'font-size': '10px','stroke': 'none', 'fill':'#D9D9D9', 'font-family': 'Clear Sans', 'dominant-baseline': 'middle'}"
        >
          {{ `${scaleY.domain()[1] * 100}%` }}
        </text>
        <text x="-13" :y="gH" class="lab-bottom"
          :style="{'text-anchor': 'end', 'font-size': '10px', 'stroke': 'none', 'fill':'#D9D9D9', 'font-family': 'Clear Sans', 'dominant-baseline': 'middle'}"
        >
          0
        </text>
      </g>

      <!-- <line x1="0" :x2="gW" :y1="gH/2" :y2="gH/2" :style="{stroke: '#7c7c7c'}"/> -->
      <rect v-for="(b, i) in bars" :key="i"
        :x="b.x"
        :y="b.y"
        :width="b.w"
        :height="b.h"
        :style="{fill: colors}"
      />
      <g class="annotation">

        <text x="0" :y="gH + 16" :style='{
          "font-size": "12px", "font-family": "Clear Sans", "font-style": "italic", "dominant-baseline": "end", "stroke": "none", "fill":"#D9D9D9"
        }'>
          {{currentLangDict.selectPeriod}}: {{ fromF }} — {{ toF }}
        </text>
      </g>
    </g>

    <text x="75" y="69"
      :style='{"font-size": slug === "external-rule" ? "20px" : "24px", "font-family": "Clear Sans", "font-weight": "bold", "stroke": "none", "fill":"#D9D9D9"}'
    >{{title}}
    </text>
    <text x="75" y="98"
      :style='{"font-size": "14px", "font-family": "Clear Sans", "stroke": "none", "fill":"#D9D9D9"}'
    >{{currentLangDict.perc}}
    </text>

  </svg>
  </figure>
  <canvas ref="canvas" :width="svgW*1.7" :height="svgH*1.7"></canvas>
  <div id="png-container"><img :src="png">
  <a ref="download" :href="png" :download="`texty_topic_radar_${slug}.png`" target="blank_"></a>
  </div>
</div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'getPng',
  props: {
    daily: Array,
    slug: String,
    days: Array,
    scaled: { default: false, type: Boolean },
    title: String,
    maxY: { default: 0.5, type: Number },
  },

  data() {
    return {
      svgH: 500,
      svgW: 750,
      gH: 300,
      gW: 625,
      png: '',
      DOMURL: null,
      types: {
        uk_man: 'Українські маніпулятивні',
        ru_for_uk_man: 'РосЗМІ для України, маніпулятивні',
        reg: 'Локальні українські видання, всі новини',
        uk: 'Провідні українські сайти, всі матеріали',
        ru: 'Провідні російські сайти, всі матеріали',
        tg: 'Популярні телеграм канали, всі матеріали',

      },
      colors: '#FF4128',
        tranlationEng: {
        "perc": "% of topic per day",
        "selectPeriod": "Select period",
      },
      translationUK: {
        "perc": "% теми серед матеріалів за день",
        "selectPeriod": "Обраний період",
      },  
    };
  },

  mounted() {
    const that = this;

    const { canvas } = this.$refs;
    const ctx = canvas.getContext('2d');
    ctx.scale(1.7, 1.7);
    const svgString = new XMLSerializer().serializeToString(this.$refs.svg);
    that.DOMURL = self.URL || self.webkitURL || self;
    const img = new Image();
    const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const url = that.DOMURL.createObjectURL(svg);


    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      const logo = new Image();
      logo.src = 'img/texty_logo.svg';
      logo.onload = function () {
        ctx.drawImage(logo, 602, 456, 100, 11.65644);
        that.png = canvas.toDataURL('image/png');
        that.$nextTick(() => {
          that.$refs.download.click();
          that.$emit('pngready');
        });
      };
      that.DOMURL.revokeObjectURL(that.png);
    };
    img.src = url;
  },


  computed: {
    currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
    },
    sitetypes() {
      const sts = this.$route.query.sitetypes;
      return sts ? sts.split(',') : ['uk_man', 'ru_for_uk_man'];
    },

    from() {
      const qFrom = this.$route.query.from;
      return qFrom ? new Date(qFrom) : this.pdate(this.days[this.days.length - 35]);
    },

    to() {
      const qTo = this.$route.query.to;
      return qTo ? new Date(qTo) : this.pdate(this.days[this.days.length - 1]);
    },

    filteredDaily() {
      const that = this;
      return that.daily
        .filter((d) => new Date(d.date) >= that.from && new Date(d.date) <= that.to);
    },

    toF() {
      return this.fdate(this.to);
    },

    fromF() {
      return this.fdate(this.from);
    },

    selectedDays() {
      const that = this;
      return that.days
        .map((d) => that.pdate(d))
        .filter((d) => d >= that.from && d <= that.to)
        .sort((a, b) => (a < b ? -1 : 1))
        .map((d) => d3.timeFormat('%Y-%m-%d')(d));
    },

    scaleX() {
      const that = this;

      return d3.scaleBand()
        .domain(that.selectedDays)
        .range([0, that.gW])
        .paddingInner(0.15);
    },

    scaleY() {
      const that = this;
      return d3.scaleLinear()
        .domain([0, that.maxY])
        .range([0, that.gH ]);
    },

    bars() {
      const that = this;

      return that.filteredDaily
        .map((d) => {
          const y_abs = that.scaleY(d.perc);
          return {
            x: that.scaleX(d.date),
            // y: that.sitetypes.indexOf(d.sitetype) > 0 ? that.gH / 2 : that.gH / 2 - y_abs,
            y: that.gH - y_abs, 
            h: y_abs,
            w: that.scaleX.bandwidth(),
            n_day: d.n,
            sitetype: d.sitetype,
          };
        });
    },
  },

  methods: {
    fdate(dt) {
      return d3.timeFormat('%d.%m.%y')(dt);
    },
    pdate(s) {
      return d3.timeParse('%d.%m.%y')(s);
    },
  },

};
</script>

<style lang="scss">
  .png {
      position: fixed;
      top: 0;
      left:0;
      min-width: 100vw;
      min-height: 100vh;
      padding: 3em;
      z-index: -9999;
      opacity: 0;
      pointer-events: none;


    figure {
      display: inline-block;
      margin: auto;
    }

    svg {
      opacity: 1 !important;
      width: 750px !important;
      height: 500px !important;
      
    }

    canvas {
      color: white;
      position: absolute;
      display: block;
      top: 0;
      background: #353535;
    }
  }
</style>
