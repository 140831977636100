<template>
  <nav id="time-selector">
    <VueSlider v-model="values"
      :dotSize="[8, 20]"
      :max="days.length - 1"
      :tooltipFormatter="getTooltip"
      :useKeyboard="true"
      :lazy="true"
      :adsorb="true"
      :enable-cross="false"
      :min-range="14"
      :silent="true"
      :min="0"
      tooltip="always"
      @change="updDates"
    />
  </nav>
</template>

<script>
import VueSlider from 'vue-slider-component';
import * as d3 from 'd3'

export default {
  name: 'Header', 
  components: {
    VueSlider
  },
  data() {
    return {
      values: [null, null]
    }
  },
  props: {
    days: Array,
  },

  created() {
    if (this.$route.query.to) {
      this.values = [
        this.days.indexOf(d3.timeFormat('%d.%m.%y')(new Date(this.$route.query.from))),
        this.days.indexOf(d3.timeFormat('%d.%m.%y')(new Date(this.$route.query.to))),
      ];
    } else {
      this.values = [
        this.days.length - 30,
        this.days.length - 1
      ];

      // вперше встановлюю дати в vuex store

      let from = this.days[this.days.length - 30] 
      let to = this.days[this.days.length - 1] 

      let ffrom = this.fdate(this.pdate(from)) 
      let fto = this.fdate(this.pdate(to)) 

      this.$store.commit('changeDateRange', {"from": ffrom, "to": fto})
    }
  },

  methods: {
    getTooltip(d) {
      return d !== null ? this.days[d] : this.days[this.days.length - 1]
    },

    updDates() {
      const that = this;
      const [from, to] = this.values
        .map(d => that.pdate(that.days[d]))
        .map(d => that.fdate(d));

      that.$router.replace({ query: { 
        from: from,
        to: to,
        sitetypes: this.$route.query.sitetypes,
      }})


//    оновлюю значення вибраних дат в vuex store 
      this.$store.commit('changeDateRange', {"from": from, "to": to})



    },
    
    fdate(d) {
      return d3.timeFormat('%Y-%m-%d')(d)
    },

    pdate(d) {
      return d3.timeParse('%d.%m.%y')(d)
    },
  }
}
</script>

<style lang="scss">
@import './assets/scss/header.scss';
</style>

