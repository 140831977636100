<template>
<div>
<slot></slot>
<section id="vis">
  <div class="header-side">
    <p>{{currentLangDict.period}}</p>
  </div>
    <header-time :days="days"/>
  <div class="header-side"></div>

  <article id="search-view">
    <svg v-if="!isMobile" id="search-lines" viewBox="0 0 350 20" preserveAspectRatio="none">
      <path d="M0 8 L208 8 L208 18 L260 18"
      vector-effect="non-scaling-stroke"/>
    </svg>

    <section id="search-chart">
      <!-- <h4 id="search-title">Пошук</h4> -->
      <h2>
        <searchmini v-if="qD && cDaily.length" :preQ="qD"/>
      </h2>
      <transition name="dropdown">
      <div v-if="cDaily.length">
        <p class="legend">
          {{currentLangDict.binDescr}},
          <br>{{currentLangDict.binDescr2}} «<em>{{ qD }}</em>»
        </p>
        <figure id="search-result">
          <chart :daily="cDaily"
            :days="days"
            :maxY="maxY"
            :axis="true"
            :annotate="true"
            :tooltip="true"
            slug="search"
            @scroll-headlines="scrollHeadlines"
          />
        </figure>
      </div>
      </transition>

      <router-link :to="{path: '/', query: $route.query}"
          class="back">
          {{currentLangDict.home}}
      </router-link>

      <div v-if="!qD && !q" id="no-search">
        <searchmini/>
      </div>
      <div v-if="(qD || q) && cDaily.length === 0 && !loading" id="no-search">
        <searchmini/>
        <p>{{currentLangDict.notArticlesFound}} «{{ qD }}»</p>
      </div>
      <div v-if="loading" id="loading">
        <img src="img/loading_icon.png"/>
      </div>

<!-- future components suggestion list for search queries -->
      <h3 id="suggestion-title">{{currentLangDict.pickQuery}}</h3>
      <ul id="possibleQ">
        <li class="suggestion-list" v-on:click="passQuery(item)" v-for="item in elementsToDisplay" :key="item">
        {{ item }}
        </li>
      </ul>
      <button id="shuffle-suggested" v-on:click="sortPossibleQueries()">{{currentLangDict.otherOptions}}</button>
    </section>

    <div id="headline-feed-container">
      <h4>{{currentLangDict.newsHeadlines}}</h4>
      <headlines :readyData="cHeadlines"
        :isMobile="isMobile"
        :to="to"
        :from="from"
        :scrollToDate="scrollToDate"
        :showLink="true"
        :addAboutFullText="true"/>
    </div>
  </article>
</section>


<Methdology/>
<TextyFooter/>


</div>
</template>

<script>
import * as d3 from 'd3';
import * as axios from 'axios';

import Methdology from './Methodology.vue';
import TextyFooter from './TextyFooter.vue';


export default {
  name: 'Search',
  components: { TextyFooter, Methdology },
  props: {
    q: {type: String, required: false},
    days: {type: Array, required: true},
  },
  metaInfo() {
      return this.$store.getters.headerMeta
  },
  data() {
    return {
      endpoint: 'https://fgz.texty.org/api/topic-radar-search-war/',
      publicPath: process.env.BASE_URL,
      daily: [],
      headlines: [],
      scrollToDate: null,
      stPeriod: {
        uk_man: { from: null, to: null, },
        ru_for_uk_man: { from: null, to: null, },
        reg: { from: null, to: null, },
        uk: { from: null, to: null, },
        ru: { from: null, to: null, },
      },
      loading: false,
      tranlationEng: {
        "newsHeadlines": "News Headlines",
        "notArticlesFound": "Have not found articles mentioning",
        "binDescr": " The bar height shows % of the articles among all the news of the day",
        "binDescr2": "that include",
        "home": "Home",
        "period": "select a period",
        "pickQuery": "Pick Search Query",
        "otherOptions": "Other queries"

      },
      translationUK: {
        "newsHeadlines": "Заголовки новин",
        "notArticlesFound": "Не знайшли матеріалів за запитом",
        "binDescr": "Висота стовпчика показує % матеріалів за день",
        "binDescr2": "які містять пошуковий запит",
        "home": "На головну",
        "period": "Оберіть період",
        "pickQuery": "Оберіть можливий запит",
        "otherOptions": "Інші варіанти"

      },  
      possibleQueries: 
        ["Азовсталь","Буча","референдум","СССР","война","спецоперация",
        "путин","Байден","Зеленский","демилитаризация","денацификация"],
      sortedPossibleQueries: []
    };
  },

  created() {
    this.$store.state.qD = this.q

    this.sortPossibleQueries()



// тут я додаю дату в роутер. Тому, що саме звідти бере дату функція яка надсилає запит і малює дані
    this.$router.replace({ query: { 
        from: this.ffrom,
        to: this.fto,
    }})


    this.askApi()
  },

  computed: {
    elementsToDisplay() {
      let n = 5;

      return this.sortedPossibleQueries.slice(0, n);

    },
    currentLangDict() {
          if (this.$store.state.lang == 'uk') {
            return this.translationUK
          }
          else {
            return this.tranlationEng
          }
    },    
    qD: function() {
      return this.$store.state.qD
    },
    isMobile: function() {
      return window.innerWidth < 768;
    },

    maxY: function() {
      const that = this;
      const m = Math.max(...that.cDaily.map((d) => d.perc)) * 100;
      return Math.ceil(m / 5) / 20;
    },

   

    fto: function() {
      const that = this;

    //   let to = d3.timeFormat('%d.%m.%y')(new Date(this.$route.query.to))

    //  return to || that.fdate(that.pdate(that.days[that.days.length - 1]));


      return this.$store.state.dateRange.to

      // return that.$route.query.to 
      //   || that.fdate(that.pdate(that.days[that.days.length - 1]));
    },

    ffrom: function() {
      const that = this;

      // let from = d3.timeFormat('%d.%m.%y')(new Date(that.$route.query.from))

      // return from || that.fdate(that.pdate(that.days[that.days.length - 35]));

      return this.$store.state.dateRange.from

      // return that.$route.query.from 
      //   || that.fdate(that.pdate(that.days[that.days.length - 35]));
    },

    minfrom: function() {
      const that = this;
      return d3.min(that.daily, d => new Date(d.date));
    },

    maxto: function() {
      const that = this;
      return d3.max(that.daily, d => new Date(d.date));
    },

    to: function() {
      return new Date(this.fto);
    },

    from: function() {
      return new Date(this.ffrom);
    },

    drange: function() {
      return [this.from, this.to];
    },

    cDaily: function() {
      const that = this;
      return that.daily.filter(function(d) {
        return new Date(d.date) <= that.to 
          && new Date(d.date) >= that.from
      });
    },

    cHeadlines: function() {
      const that = this;
      return that.headlines
        .map(function(d) {
          d.date = new Date(d.date);
          return d;
        })
        .sort((a, b) => a.date > b.date ? -1 : 1)
        .filter(d =>  d.date <= that.to && d.date >= that.from);
    },
  },

  methods: {
    askApi() {

      this.$router.replace({ query: { 
        from: this.ffrom,
        to: this.fto,
    }})

      const that = this;
      if (!that.qD) {
        return;
      }
      that.loading = true
      const d = {
        q: that.qD,
        dfrom: that.ffrom,
        dto: that.fto,
      };

      that.daily = [];
      that.headlines = [];

      axios.get(that.endpoint+'daily/', {params: d})
        .then(function(r) {
          that.daily = r.data;

          that.loading = false;
        }).catch(function(err) {
          console.log(err)
          that.loading = false;
        });

      axios.get(that.endpoint+'headlines/', {params: d})
        .then(function(r) {
          that.headlines = r.data;
          that.loading = false;
        }).catch(function(err) {
          console.log(err)
          that.loading = false;
        });
    },

    checkUpdDates([nfrom, nto], [ofrom, oto]) {
      if (!this.qD) {
        return;
      }
      const that = this;
      const fromO = d3.min([that.minfrom, ofrom]) - nfrom;
      const toO = nto - d3.max([that.maxto, oto]);
      if (fromO <= 0 & toO <=0) {
        return;
      }

      const d = {
        q: that.qD,
      }
      const dayMs = 24*60*60*1000;

      if (fromO > 0 || toO > 0) {
        that.headlines = [];
        axios.get(that.endpoint+'headlines/', { params: {
          ...d,
          dfrom: that.ffrom,
          dto: that.fto,
        }})
          .then(function (r) {
            that.headlines = r.data;
          });
      };

      if (fromO > 0 && toO <= 0) {
        const params = {
          ...d,
          dfrom: that.fdate(new Date(nfrom.valueOf() + dayMs)),
          dto: that.fdate(new Date(nfrom.valueOf() + fromO)),
        };
        axios.get(that.endpoint+'daily/', { params, })
          .then(function (r) {
            that.daily = that.daily.concat(r.data);
          });
      };
      
      if (toO > 0 && fromO <= 0) {
        const params = {
          ...d,
          dfrom: that.fdate(new Date(nto.valueOf() - toO + dayMs)),
          dto: that.fdate(nto),
        };
        axios.get(that.endpoint+'daily/', { params, })
          .then(function (r) {
            that.daily = that.daily.concat(r.data);
          });
      };
    },

    checkUpdSites(nsts, osts) {
      if (!this.qD) {
        return;
      }
      const that = this;
      const params = {
        q: that.qD,
        dfrom: that.ffrom,
        dto: that.fto,
      };
      that.headlines = [];
      axios.get(that.endpoint+'headlines/', { params: {
        ...params,
      }}).then(function (r) {
        that.headlines = r.data;
      })
      axios.get(that.endpoint+'daily/', {params: {
        ...params,
      }}).then(function (r) {
        that.daily = that.daily.concat(r.data);
      });
    },

    passQuery(q) {
      this.$store.state.qD = q;
      askApi()
    },

    fdate(dt) {
      return d3.timeFormat('%Y-%m-%d')(dt)
    },

    pdate(s) {
      return d3.timeParse('%d.%m.%y')(s)
    },

    scrollHeadlines(e) {
      this.scrollToDate = e;
    },
    sortPossibleQueries() {
      let data = this.possibleQueries 
      let shuffled = data.sort(() => 0.5 - Math.random());

      this.sortedPossibleQueries = shuffled;
    }
  },

  watch: {
    'drange': 'checkUpdDates',
    'qD': 'askApi',
    // 'q': 'passQ',
  },
};

</script>

<style lang="scss">
@import './assets/scss/search.scss';
</style>
